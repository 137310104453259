module.exports = {
  siteTitle: 'Colin Boyd | Creative Technologist',
  siteDescription:
    'Colin Boyd is a software engineer based in Chicago, Illinois who specializes in solving problems with technology.',
  siteKeywords:
    'Colin Boyd, Colin, Boyd, cboyd, boydco, COBO Digital, software engineer, front-end engineer, web developer, javascript',
  siteUrl: 'https://colinboyd.me',
  siteLanguage: 'en_US',
  googleAnalyticsID: 'UA-48772051-7',
  googleVerification: 'T5eh4sk2IJa4UsZJ37EPMGg79MBJZfnCvRAx8S2CczE',
  name: 'Colin Boyd',
  location: 'Chicago, IL',
  email: 'hi@colinboyd.me',
  github: 'https://github.com/boydco',
  twitterHandle: '@colinboydtweets',
  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/boydco',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/colinboyd2/',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/colin._.boyd',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/colinboydtweets',
    },
  ],

  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    {
      name: 'Experience',
      url: '/#jobs',
    },
    {
      name: 'Work',
      url: '/#projects',
    },
    {
      name: 'Contact',
      url: '/#contact',
    },
  ],

  navHeight: 100,
  greenColor: '#ecce8e',
  navyColor: '#0a192f',
  darkNavyColor: '#020c1b',
  whiteColor: '#ffffff',
  blackColor: '#111111',
  salmonColor: '#ad5858',

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
