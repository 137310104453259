import React from 'react';

const IconLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g id="Logo" transform="translate(0.000000, 0.000000)">
      <g>
        <path
          d="M 28.26 9.96 L 20.4 12.06 A 4.905 4.905 0 0 0 20.055 11.449 Q 19.603 10.775 19.069 10.612 A 1.19 1.19 0 0 0 18.72 10.56 Q 18.021 10.56 17.274 11.149 A 5.296 5.296 0 0 0 16.71 11.67 A 11.959 11.959 0 0 0 15.797 12.768 Q 15.36 13.358 14.927 14.066 A 22.156 22.156 0 0 0 14.64 14.55 A 36.556 36.556 0 0 0 13.511 16.674 A 46.275 46.275 0 0 0 12.66 18.51 Q 11.7 20.7 10.98 22.92 A 50.561 50.561 0 0 0 10.185 25.613 A 41.894 41.894 0 0 0 9.81 27.15 A 25.354 25.354 0 0 0 9.573 28.333 Q 9.374 29.473 9.361 30.304 A 7.348 7.348 0 0 0 9.36 30.42 Q 9.36 31.927 9.674 32.732 A 2.112 2.112 0 0 0 9.87 33.12 A 1.946 1.946 0 0 0 10.259 33.564 A 1.467 1.467 0 0 0 11.22 33.9 A 2.082 2.082 0 0 0 11.786 33.816 Q 12.221 33.693 12.72 33.39 A 6.608 6.608 0 0 0 13.936 32.434 A 7.766 7.766 0 0 0 14.28 32.07 Q 15 31.26 15.6 30.24 A 7.907 7.907 0 0 0 16.198 28.997 A 6.607 6.607 0 0 0 16.44 28.2 L 23.28 30.96 Q 21.84 34.26 20.07 36.69 Q 18.3 39.12 16.35 40.77 Q 14.4 42.42 12.42 43.2 A 11.476 11.476 0 0 1 10.23 43.831 A 9.383 9.383 0 0 1 8.58 43.98 A 7.122 7.122 0 0 1 6.077 43.546 A 6.681 6.681 0 0 1 5.19 43.14 Q 3.66 42.3 2.52 40.62 Q 1.38 38.94 0.69 36.48 A 17.467 17.467 0 0 1 0.195 33.989 Q 0 32.473 0 30.72 Q 0 25.08 1.56 19.59 A 38.074 38.074 0 0 1 4.415 12.345 A 33.499 33.499 0 0 1 5.94 9.72 A 26.14 26.14 0 0 1 9.875 4.913 A 22.594 22.594 0 0 1 12.63 2.67 Q 16.5 0 21.18 0 Q 22.62 0 23.94 0.6 Q 25.26 1.2 26.22 2.28 A 7.548 7.548 0 0 1 27.312 3.905 A 9.451 9.451 0 0 1 27.75 4.92 A 9.335 9.335 0 0 1 28.25 7.095 A 11.755 11.755 0 0 1 28.32 8.4 L 28.32 9.15 A 5.55 5.55 0 0 1 28.286 9.752 A 6.312 6.312 0 0 1 28.26 9.96 Z"
          fill="#ecce8e"
          transform="translate(24, 20)"
        />

        <polygon
          id="Shape"
          stroke="#ecce8e"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
